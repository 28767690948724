import Compressor from "compressorjs";

const compress = (file, quality = 0.6) => {
	return new Promise((resolve, reject) => {
		new Compressor(file, {
			quality: quality,
			maxWidth: 1500,
			maxHeight: 2000,
			minWidth: 700,
			minHeight: 500,
			success(result) {
				resolve(result);
			},
			error(error) {
				reject(error)
			},
		})
	})
}

export default {
	compress
}
