import Config from "@/vendor/config"
import Request from "@/api/request.js"
import Compress from "@/vendor/compress.js"

const image = (file) => {
	return new Promise((resolve, reject) => {
		Compress.compress(file)
			.then(result => {
				let formData = new FormData();
				formData.append('image', result, result.name);
				return Request.setBaseUrl(Config.imApiUrl).request(Request.post, "/upload/image",
					formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
			})
			.then(result => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			})
	})
}

const voice = (localId) => {
	return new Promise((resolve, reject) => {

		wx.uploadVoice({
			localId: localId,
			isShowProgressTips: 0,
			success: (result) => {
				Request.setBaseUrl(Config.imApiUrl).request(Request.post, '/upload/media', {
					server_id: result.serverId
				})
					.then(result => {
						resolve(result);
					})
					.catch(error => {
						reject(error);
					})
			},
			error: (error) => {
				reject(error)
			}
		})
	})
}

export default {
	image,
	voice
}
